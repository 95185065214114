<template>
  <div
    v-if="inventory && inventory.length"
    class="grow d-grid flex-column px-0 mx-0"
  >
    <VRow class="shrink ma-0">
      <VCol cols="3">
        <VAutocomplete
          v-model="event"
          placeholder="Event Type"
          :items="events"
          dark
          dense
          class="pt-4"
          style="z-index: 500;"
        />
      </VCol>

      <VCol
        offset="5"
        cols="2"
      >
        <CMDatePicker
          identifier="start"
          :label="$t('grid_monitor.date_start')"
           :value="timeSelection.start || ''"
          @updateDate="updateDate"
        />
      </VCol>

      <VCol cols="2">
        <CMDatePicker
          identifier="end"
          :min="timeSelection.start || ''"
           :label="$t('grid_monitor.date_end')"
          :value="timeSelection.end || ''"
          left="70"
          @updateDate="updateDate"
        />
      </VCol>
    </VRow>
    <VRow class="grow ma-0">
      <VCol
        cols="12"
        class="ma-0 py-0"
      >
        <CMTable
          :show-select="true"
          :page-size="12"
          :single-select="false"
          origin="measurements.inventory"
          :loading="loading"
          :header-config="{
            TYPE: { advanced: 'numeric' },
            SAMPLING_TIME: { advanced: 'numeric' },
            INSTALLATION_DATE: { advanced: 'date' },
            STATION: { advanced: 'numeric' },
            TRANSFORMER: { advanced: 'numeric' },
            FEEDER: { advanced: 'numeric' },
            CGP: { advanced: 'numeric' },
            PHASE: { advanced: 'numeric' }
          }"
          :custom-title="headerTranslations"
          :show-sync-map-btn="true"
          @onToggleSelectAll="onToggleSelectAll"
          @onItemSelected="updateSelected"
        />
      </VCol>
    </VRow>

    <VRow class="shrink bottom-section">
      <VCol
        v-if="feedback.visible"
        cols="12"
        class="p-feedback"
      >
        <VAlert
          v-if="feedback.visible"
          dense
          :type="feedback.type"
          elevation="5"
          transition="slide-y-transition"
        >
          {{ feedback.message }}
        </VAlert>
      </VCol>

      <VCol cols="2">
        <VTextField
          v-model="aggregation"
          label="Aggregation"
          hint="Enter a name"
          clearable
        />
      </VCol>

      <VCol cols="2">
        <VAutocomplete
          v-model="interval"
          label="Time Interval"
          :disabled="!selected.length"
          :items="intervals"
          dark
          dense
          class="pt-4"
          style="z-index: 500;"
        />
      </VCol>

      <VCol
        cols="2"
        class="d-flex"
      >
        <VBtn
          color="primary black--text"
          :disabled="setDisabledSendToPlot(event, interval)"
          @click="sendToPlot"
        >
          {{ loading ? $t('grid_monitor.sending') : $t('grid_monitor.send_to_plot') }}
        </VBtn>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { getEvents, getEventsPlotData } from '@/services/measurements'
import MSTableMixin from '@/mixins/Measurements/MSTableMixin'
import vuexMixin from '@/mixins/vuexMixin'
import MSCommonTabs from '@/mixins/Measurements/MSCommonTabs'

export default {
  name: 'MSEventsTab',

  mixins: [MSTableMixin, vuexMixin, MSCommonTabs],

  data: () => ({
    event: null,
    interval: null,
    events: []
  }),

  computed: {
    intervals () {
      return [
        { text: 'PER MINUTE', value: '1m' },
        { text: 'PER HOUR', value: '1h' },
        { text: 'PER DAY', value: '1d' },
        { text: 'PER WEEK', value: '7d' }
      ]
    },

    headerTranslations () {
      return [
        { value: 'NAME', text: 'name' },
        { value: 'TYPE', text: 'type' },
        { value: 'SAMPLING_TIME', text: 'grid_monitor.sampling_time' },
        { value: 'INSTALLATION_DATE', text: 'installation_date' },
        { value: 'STATION', text: 'grid.station' },
        { value: 'FEEDER', text: 'grid.feeder' },
        { value: 'CGP', text: 'grid.connection_point' },
        { value: 'PHASE', text: 'phase' },
        { value: 'TRANSFORMER', text: 'grid.transformer' }
      ]
    }
  },

  async mounted () {
    this.events = await getEvents()
  },

  methods: {
    async sendToPlot () {
      this.toggleLoading()

      const commonProps = {
        event: this.event,
        interval: this.interval
      }

      const plotData = await getEventsPlotData({
        ...commonProps,
        meters: this.selected.map(meter => meter.ID),
        timeStart: this.timeSelection.start,
        timeEnd: this.timeSelection.end,
        aggregation: this.aggregation
      })

      if (plotData.time && (!plotData.time.length || !plotData.value.length)) {
        plotData.error = true
      }

      if (plotData.error) {
        this.setVuexElement({
          path: 'measurements.feedback',
          value: {
            type: 'error',
            message: 'No data found with parameters entered',
            visible: true
          }
        })

        this.toggleLoading()
        return
      }

      this.setVuexElement({
        path: 'measurements.plotData',
        value: [
          ...this.plotData,
          {
            ...plotData,
            ...commonProps,
            timeSelection: this.timeSelection,
            selector: this.currentSelector.title,
            origin: 'backend',
            tab: 'events'
          }
        ]
      })

      this.aggregation = ''

      this.toggleLoading()
      this.clearSelection()
    }
  }
}
</script>

<style scoped>
::v-deep .v-menu__content {
  z-index: 503 !important;
}

::v-deep .v-text-field.v-input--dense .v-label {
  top: 0 !important
}

.p-feedback {
  padding: 20px 100px;
}

.bottom-section {
  margin: 0;
  display: flex;
  align-items: center;
}
</style>
